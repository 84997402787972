<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-details" v-if="!isMobile">
      <v-row class="mt-11 pl-8 pr-8">
        <v-col cols="9">
          <img
            height="400"
            class="img-banner rounded-lg"
            :src="details.img_full"
          />
          <v-card elevation="0" color="#f6f9ff">
            <v-card-title class="pb-1 pt-4">
              <p class="text-title-dark-blue mb-0 title-news-break">
                <strong>{{ details.post_title }}</strong>
              </p>
              <v-spacer></v-spacer>
              <v-chip text-color="#fff" color="#466BE3" class="mt-2" small>
                {{ details.tagName }}
              </v-chip>
            </v-card-title>
            <p class="text-small-gray pl-5 pt-1 pb-2">
              {{ details.post_date }}
            </p>
            <div
              class="pb-4 pl-4 pr-4 style-html"
              style="color: #626262 !important"
              v-html="details.description"
            ></div>

            <template  v-if="showVideo2">
              <video
                :class="
                  details.url_video == ''
                    ? 'news-details__hide-video'
                    : 'news-details__show-video'
                "
                style="background-color: #000; width: 95%"
                ref="videoA"
                id="videoAutoDetails"
                controls
                loop
              />
            </template>
          </v-card>
        </v-col>
        <v-col cols="3">
          <!-- <p class="news_details__title mt-0">Otras noticias</p> -->
          <v-row>
            <v-col v-for="item in listOthersNews" :key="item.post_id" cols="12">
              <v-card
                elevation="1"
                class="mx-auto rounded-lg news-details__other-news"
                max-width="300"
              >
                <v-img
                  class="rounded-lg"
                  height="200"
                  :src="item.img_full"
                  style="
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  "
                ></v-img>

                <v-card-title class="text-small pb-2 pt-5">
                  {{ item.post_date }}
                  <v-spacer></v-spacer>
                  <v-chip text-color="#fff" color="#466BE3" small>
                    {{ item.tagName }}
                  </v-chip>
                </v-card-title>

                <v-card-title
                  class="text-title-1 pt-2 pb-1 news-details__other-news-title"
                >
                  {{ item.post_title }}
                </v-card-title>

                <v-card-text
                  style="
                    height: 60px;
                    color: #626262 !important;
                    overflow: hidden;
                  "
                  class="text-basic mb-2 mt-2"
                >
                  <div v-html="item.description"></div>
                </v-card-text>
                <v-card-actions>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      @click="irOther(item.post_id)"
                      class="mb-1"
                      block
                      rounded
                      style="color: #fff"
                      :style="{
                        'background-color': hover ? '#324CA1' : '#466BE3',
                      }"
                      >VER MÁS</v-btn
                    >
                  </v-hover>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-details pb-14" v-else>
      <div style="display: flex">
        <v-btn
          @click="$router.go(-1)"
          x-small
          fab
          icon
          style="width: 30px; margin-top: 30px"
        >
          <img width="20" src="../../assets/icon/navigate_before.png" />
        </v-btn>
        <p class="mb-0 pb-0 pl-2 pr-2 mt-6 txt-title-details">
          {{ details.post_title }}
        </p>
      </div>
      <p class="text-small-gray pl-10 pr-2 pt-1 pb-0 mb-0">
        {{ details.post_date }}
      </p>
      <img
        id="img-details-sm"
        class="img-banner pt-6 pl-2 pr-2 pb-4"
        :src="details.img_full"
      />

      <div
        class="pb-4 pl-2 pr-2 style-html"
        style="color: #898989"
        v-html="details.description"
      ></div>

      <template v-if="showVideo2">
        <video
          controls
          v-if="details.url_video != ''"
          style="background-color: #000; width: 95%"
          ref="videoB"
          id="videoAutoDetailsMobile"
          loop
          class="ml-2 mr-2 mb-4"
        />
      </template>
      <template v-else>
        <div class="d-flex justify-center">
          <vimeo-player
            ref="player"
            video-id="1"
            :video-url="videoUrl"
            class="news-details__embed-container-mobile"
            :options="{ responsive: true }"
          />
        </div>
      </template>
    </div>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import Hls from "hls.js";
import $ from "jquery";
import moment from "moment";
export default {
  mixins: [alert],
  name: "Views",
  components: {
    Hls,
  },
  data() {
    return {
      isMobile: false,
      dimensions: screen,
      dimensionsImg: null,
      heightCard: null,
      id: this.$route.params.id,
      loading: {
        btn: false,
      },
      details: {},
      listOthersNews: [],
      loadingAll: false,
      videoUrl: "",
      showVideo: true,
      showVideo2: false,
    };
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    irOther(id) {
      this.id = id;
      this.$router.push({ name: "newsDetails", params: { id: id } });
      this.showView();
      this.showOtherView();
    },
    showOtherView() {
      this.loadingAll = true;
      Api.noAuth()
        .showOtherViews(this.id)
        .then((resp) => resp.json())
        .then((data) => {
          this.loadingAll = false;
          if (data.cod == 0) {
            var listOthers = data.data;
            this.listOthersNews = [];
            for (let i = 0; i < 3; i++) {
              if (listOthers[i]) {
                let date = moment(listOthers[i].post_date).format("L");
                listOthers[i].post_date = date;
                if (listOthers[i].tags.length > 0) {
                  listOthers[i].tagName = listOthers[i].tags[0];
                } else {
                  listOthers[i].tagName = "Otro";
                }
                this.listOthersNews.push(listOthers[i]);
              }
            }
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          console.log(error);
        });
    },
    showView() {
      (this.videoUrl = ""), (this.loadingAll = true), (this.showVideo = true);
      Api.noAuth()
        .showView(this.id)
        .then((resp) => resp.json())
        .then((data) => {
          this.showVideo2 = false;
          this.loadingAll = false;
          if (data.cod == 0) {
            this.details = data.data;
            let date = moment(this.details.post_date).format("L");
            this.details.post_date = date;
            if (this.details.tags != null && this.details.tags.length > 0) {
              this.details.tagName = this.details.tags[0];
            } else {
              this.details.tagName = "Otro";
            }
            if (this.details.url_video != null) {
              // VIDEO
              let videoSrc = this.details.url_video;
              let format = videoSrc.includes("m3u8");
              let vimeo = videoSrc.includes("vimeo");
              if (format) {
                this.showVideo = true;
                this.showVideo2 = true;

                setTimeout(() => {
                  let video = document.getElementById("videoAutoDetails");
                  let videoMobile = document.getElementById(
                    "videoAutoDetailsMobile"
                  );
                  video.src = videoSrc;
                  if (screen.width < 1024) {
                    videoMobile.src = videoSrc;
                  }
                  if (Hls.isSupported()) {
                    let hls = new Hls();
                    hls.loadSource(videoSrc);
                    hls.attachMedia(video);
                    let hlsMobile = new Hls();
                    hlsMobile.loadSource(videoSrc);
                    hlsMobile.attachMedia(videoMobile);
                  } else if (
                    video.canPlayType("application/vnd.apple.mpegurl")
                  ) {
                    video.src = videoSrc;
                    videoMobile.src = videoSrc;
                  }
                }, 1500);
              }
              if (vimeo) {
                this.showVideo2 = false;
                this.showVideo = false;
                this.videoUrl = this.details.url_video;
              }
            }
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.showOtherView();
    this.showView();
    this.dimensionsImg = $("#img-details-sm").css("height");
    var valueImg = this.dimensionsImg.replace("px", "");
    this.heightCard = this.dimensions.height - valueImg - 200;
  },
  created() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main.less";
.news-details__hide-video {
  visibility: hidden !important;
}
.news-details__show-video {
  visibility: initial !important;
}
.img-banner {
  width: 100%;
  object-fit: cover;
}
.news-details__embed-container-mobile {
  width: 95% !important;
}

.news-details__embed-container-desktop {
  width: 100% !important;
}
.style-html {
  text-align: initial;
  white-space: break-spaces;
}

.news_details__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 35px;
  color: #353535;
  text-align: left;
}

.text-basic-details {
  font-family: "RobotoRegular" !important;
  text-align: initial !important;
  font-size: 16px !important;
  color: #353535 !important;

  --lines: 2 !important;
  -webkit-line-clamp: var(--lines) !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  display: -webkit-inline-box !important;
  text-overflow: ellipsis !important;
  max-height: 3.3em !important;
  line-height: 1.8em !important;
}
.text-title-1-details {
  --lines: 1 !important;
  -webkit-line-clamp: var(--lines) !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  display: -webkit-inline-box !important;
  text-overflow: ellipsis !important;
  max-height: 2.3em !important;
  line-height: 1.8em !important;
}
.text-title-details {
  text-align: initial !important;
  font-family: "RobotoRegular" !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  color: #353535 !important;
}

.video {
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}

.player {
  position: initial !important;
}

.mb-sm-details {
  display: none;
}

.title-news-break {
  word-break: break-word !important;
}

.new_details__side-card {
  max-width: 100%;
  overflow: hidden;
}

.news-details__other-news:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.news-details__other-news-title {
  text-align: left;
  display: flex !important;
  word-break: break-word !important;
  font-family: "RobotoRegular" !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-details {
    display: none;
  }
  .mb-sm-details {
    display: block;
    text-align: initial;
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
    font-family: "RobotoRegular";
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 20px;
    line-height: 38px !important;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-small-gray {
    font-weight: 300;
    font-size: 14px !important;
    line-height: 17px;
    color: #626262 !important;
    font-family: "RobotoRegular";
  }

  .card-details {
    position: absolute;
    top: 490px;
    height: 100%;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-details {
    display: none;
  }
  .mb-sm-details {
    display: block;
    text-align: initial;
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-small-gray {
    font-weight: 300;
    font-size: 12px !important;
    line-height: 17px;
    color: #626262 !important;
    font-family: "RobotoRegular";
  }
  .style-html {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #898989;
    font-family: "RobotoRegular";
  }

  .card-details {
    position: absolute;
    top: 10px;
    height: 100%;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-details {
    display: none;
  }
  .mb-sm-details {
    display: block;
    text-align: initial;
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
    font-family: "RobotoRegular";
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-small-gray {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #626262 !important;
    font-family: "RobotoRegular";
  }
  .style-html {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    font-family: "RobotoRegular";
  }

  .card-details {
    position: absolute;
    top: 260px;
    height: 100%;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-details {
    display: none;
  }
  .mb-sm-details {
    display: block;
    text-align: initial;
  }
  .txt-title-details {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .text-small-gray {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #626262 !important;
    font-family: "RobotoRegular";
  }
  .style-html {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    font-family: "RobotoRegular";
  }

  .card-details {
    position: absolute;
    top: 250px;
    height: 100%;
  }
}
</style>
